import React from "react";
import { Table } from "react-bootstrap";
import DashboardLayout from "../../components/Layout/DashboardLayout";

const Dashboard = () => {
  return (
    <DashboardLayout>
      <h2>Dashboard</h2>
    </DashboardLayout>
  );
};

export default Dashboard;
